import React, { Component } from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Axios from "axios"
import { db, colors } from "../components/siteVars"
import { navigate, Link } from "gatsby"
import Contentbox from "../components/contentbox"
import Input from "../components/input"
import Button from "../components/button"
import Iframe from "../components/iframe"
import popupAlert from "../components/popup-alert"
import Loader from "../components/loader"

const StyledRegisterPage = styled.div`
  display: grid;
  grid-template-columns: 4fr 10fr;
  grid-gap: 1rem;
  form {
    display: flex;
    flex-direction: column;
  }
  h1 {
    margin-top: 0;
    &.h1-less-margin {
      margin-bottom: 0;
    }
  }
  .go-to-login {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .centered-text {
    text-align: center;
  }
  .get-pro-button {
    width: 100%;
    display: block;
    button {
      width: 100%;
    }
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const LoginPageLoader = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 200ms ease-in-out;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  &.active {
    opacity: 1;
    pointer-events: initial;
  }
`

export default class RegisterPage extends Component {
  state = {
    username: "",
    email: "",
    password: "",
    passwordRepeat: "",
    loading: false,
  }

  checkPassMatch = (a, b) => {
    if (a === b) return true
    else return false
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  registerUser = e => {
    this.setState({
      loading: true,
    })
    e.preventDefault()
    const { username, passwordRepeat, password, email } = this.state
    if (this.checkPassMatch(password, passwordRepeat)) {
      const body = {
        username: username,
        password: password,
        email: email,
      }
      Axios.post(db.lambda + "users/register", body)
        .then(res => {
          this.setState({
            loading: false,
          })
          console.log(res.data)
          popupAlert(res.data.message)
          setTimeout(() => {
            navigate("/planner")
          }, 1000)
        })
        .catch(err => {
          this.setState({
            loading: false,
          })
          popupAlert(err.response.data)
        })
    } else {
      popupAlert("Passwords don't match")
      this.setState({
        loading: false,
      })
    }
  }

  render() {
    const link = `
    <iframe
    width="1280"
    height="720"
    src="https://www.youtube.com/embed/wcBNXB69YL8"
    frameborder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
    />
    `
    return (
      <>
        <LoginPageLoader className={this.state.loading ? "active" : ""}>
          <Loader dualRing color={colors.mediumGrey} />
        </LoginPageLoader>
        <Layout background>
          <StyledRegisterPage>
            <Contentbox background>
              <h1 className="h1-less-margin">Register</h1>
              <p>
                Create a free account and get access to the teamgym planner.
              </p>
              <form onSubmit={this.registerUser}>
                <Input
                  placeholder="Email"
                  label="Email"
                  name="email"
                  onChange={this.onChange}
                  value={this.state.email}
                  type="text"
                />
                <Input
                  placeholder="Username"
                  label="Username"
                  name="username"
                  onChange={this.onChange}
                  value={this.state.username}
                  type="text"
                />
                <Input
                  placeholder="Password"
                  label="Password"
                  name="password"
                  onChange={this.onChange}
                  value={this.state.password}
                  type="password"
                />
                <Input
                  placeholder="Repeat password"
                  label="Repeat password"
                  name="passwordRepeat"
                  onChange={this.onChange}
                  value={this.state.passwordRepeat}
                  type="password"
                />
                <Button color="green" type="submit">
                  Register
                </Button>
                <p className="centered-text">or</p>
                <Link to="/pro" className="get-pro-button">
                  <Button type="button">Get pro</Button>
                </Link>
              </form>
              <div className="go-to-login">
                <p>Already have an accout?</p>
                <Link to="/login">
                  <Button type="button">Log in</Button>
                </Link>
              </div>
            </Contentbox>
            <Iframe>{link}</Iframe>
          </StyledRegisterPage>
        </Layout>
      </>
    )
  }
}
